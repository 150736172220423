import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import React from 'react'
import styled from 'styled-components'
import ServiceContent from './service-content'
import TextBlock from './text-block'

export default function ServicesSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicServicesPage {
        data {
          intro_text {
            html
          }
          page_heading {
            text
          }
          services {
            body_copy {
              html
            }
            bullet_points {
              html
            }
            heading {
              text
            }
            primary_image {
              alt
              url
              dimensions {
                height
                width
              }
              thumbnails {
                small {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                large {
                  url
                }
                medium {
                  url
                }
              }
            }
            secondary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                width
                height
              }
            }
            tertiary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                height
                width
              }
            }
            quaternary_image {
              url
              alt
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicServicesPage.data.page_heading.text,
    introText: data.prismicServicesPage.data.intro_text?.html,
    services: data.prismicServicesPage.data.services,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />
      <Container type="wide">
        <div className="row">
          <div className="intro">
            <div className="intro-text">
              <TextBlock>
                <div dangerouslySetInnerHTML={{__html: prismicData.introText}} />
              </TextBlock>
            </div>
          </div>
          <div className="sections-wrapper">
            {prismicData.services.map((item, index) => (
              <ServiceContent
                key={index}
                cssModifier=""
                bodyCopyHtml={item.body_copy.html}
                bulletPointsHtml={item.bullet_points.html}
                headingText={item.heading.text}
                primaryImage={item.primary_image}
                secondaryImage={item.secondary_image}
                tertiaryImage={item.tertiary_image}
                quarternaryImage={item.quaternary_image}
              />
            ))}
          </div>
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 850px) {
      flex-direction: row;
    }
  }

  .intro-image {
    margin-bottom: 6rem;

    @media (min-width: 850px) {
      width: 50%;
    }
  }

  .sections-wrapper {
    > div {
      &:first-child {
        margin: 2rem 0;
      }

      &:last-child {
        margin: 2rem 0 0 0;
      }
    }
  }
`
